import App from './App.svelte'

// Sentry.init({
//   dsn: 'https://421a3e5a32d94b149d5e1eccb8af6f24@sentry.io/1771039'
// })

const app = new App({
  target: document.body
})

export default app
